
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/clap.ts";
import { parseTime } from "@/utils";
@Options({
  name: "Clap",
})
export default class extends Vue {
  private allQuestions = [
    { text: "全部问题", value: 1 },
    { text: "分配处理人员", value: 2 },
    { text: "我处理的", value: 3 },
    { text: "我审核的", value: 4 },
    { text: "通知我的", value: 5 },
    { text: "我创建的", value: 6 },
  ];
  private allStatus = [
    { text: "全部状态", value: null },
    { text: "未处理", value: 1 },
    { text: "已处理", value: 2 },
    { text: "已解决", value: 3 },
    { text: "超期未解决", value: 4 },
  ];
  private selectType = 1;
  private selectStatus = null;
  refreshing = false;
  loading = false;
  finished = false;
  calendarShow = false;
  date: Date | string = "";

  safeList: any[] = [];
  page = {
    pageSize: 10,
    pageNum: 1,
  };

  mounted() {
    (this as any).$mitter.on("clapRefresh", () => {
      this.refreshing = true;
      this.onRefresh();
    });
  }

  fGoDetail(ployload: any) {
    this.$router.push({
      name: "Clapdetail",
      params: {
        id: ployload.id,
        areaId: ployload.areaId,
        checkType: this.selectType,
        status: ployload.status,
      },
    });
  }
  fGoAdd() {
    this.$router.push("/app/clapadd");
  }
  currentDate() {
    return parseTime(this.date, "{m}月{d}日");
  }
  fConfirmDate(d: Date) {
    this.date = d;
    this.fChangeSearch();
    this.calendarShow = false;
  }
  fChangeSearch() {
    this.refreshing = true;
    this.onRefresh();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.safeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }

      const reqjson = {
        projectId: this.$store.state.project.projectinfo.projectId,
        ...this.page,
        status: this.selectStatus,
        createDate: this.date ? parseTime(this.date, "{y}-{m}-{d}") : null,
      };
      let runfun = null;
      if (this.selectType === 1) {
        runfun = api.mobileanyPhotopageAll(reqjson);
      } else if (this.selectType === 2) {
        runfun = api.mobileanyPhotopageAllocate(reqjson);
      } else if (this.selectType === 3) {
        runfun = api.mobileanyPhotopageDeal(reqjson);
      } else if (this.selectType === 4) {
        runfun = api.mobileanyPhotopageCheck(reqjson);
      } else if (this.selectType === 5) {
        runfun = api.mobileanyPhotopageNotice(reqjson);
      } else {
        runfun = api.mobileanyPhotopageCreate(reqjson);
      }
      runfun.then((res) => {
        this.safeList = [...this.safeList, ...res.data.records];
        this.page.pageNum++;
        this.loading = false;
        if (this.safeList.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }
  fSetTime() {
    this.date = "";
    this.calendarShow = false;
    this.fChangeSearch();
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
